import { Injectable } from '@angular/core';
import * as gql from './facebook.gql';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ICustomListFacebookTermsAndConditions } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class FacebookService {
  constructor(private apollo: Apollo) {}

  getCustomListFacebookTermsAndConditions(): Observable<ICustomListFacebookTermsAndConditions> {
    return this.apollo
      .watchQuery<{ customListFacebookTermsAndConditions: ICustomListFacebookTermsAndConditions }>({
        fetchPolicy: 'network-only',
        query: gql.getCustomListFacebookTermsAndConditions,
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data.customListFacebookTermsAndConditions;
        }),
        catchError(() => of(null)),
      );
  }
}
