import { ILocation } from './location.interface';
import {
  ChannelName,
  InstagramChannel,
  FacebookChannel,
  GoogleChannel,
  CampaignOperationType,
  CampaignType,
  CampaignStatusType,
  IChannelData,
  BiddingStrategyType,
} from './channel.interface';
import { IOrganization } from './organization.interface';
import { IObjective } from './objective.interface';
import { DropdownOption } from './general.interface';
import { FormGroup } from '@angular/forms';
import { ICampaignTemplateFeedback } from './campaign-template-feedback.interface';
import { ICampaignMetrics } from './reporting.interface';

export interface CampaignTemplate extends CampaignTemplateInput {
  campaignTemplateId?: string;
  campaignTemplateNme: string;
  organizationId?: string;
  campaignTemplateSharedLocationIds?: Array<number | string>;
  organization?: IOrganization;
  campaignChannelId: string;
  channel: IChannelData;
  campaignChannelNme: ChannelName;
  campaignTemplateNewCampaignInd: boolean;
  campaignTemplateAudienceGender?: string;
  campaignTemplateMinAge?: number;
  campaignTemplateMaxAge?: number;
  campaignTemplateRecommendedDuration: number;
  campaignTemplateAudienceSuggestedSpend: number;
  campaignTemplateAvailableStartDte?: Date;
  campaignTemplateAvailableEndDte?: Date;
  campaignTemplateCampaignCreator: string;
  campaignTemplateImageUrls: Array<string>;
  campaignNme?: string;
  campaignTemplateAdDsc?: string;
  campaignTemplateHeadlines?: Array<string>;
  campaignTemplateNewsFeedlinkDscs?: Array<string>;
  campaignTemplateActiveInd: boolean;
  campaignTemplateTakeRate?: number;
  campaignTemplateRunTims?: number;
  campaignTemplateCreatedAt?: Date;
  campaignTemplateGoal: string;
  campaignTemplateObjectiveObjects: Array<IObjective>;
  campaignTemplateObjectives: Array<string>;
  campaignTemplateOptimizationGoal?: string;
  campaignTemplateDefaultWebsiteUrl?: string;
  campaignTemplateWebsiteUrls?: Array<{ url: string; locationId: string }>;
  campaignTemplateInProgressQty?: number;
  campaignTemplateSpecialAdCategory?: string;
  campaignTemplateRadius?: number;
  campaignTemplateRateFeedback?: {
    averageRate: number;
    rateCount: number;
  };
  campaignTemplateUserFeedback?: ICampaignTemplateFeedback;
  campaignTemplateFeedback?: ICampaignTemplateFeedback[];
  campaignTemplateMetrics?: {
    impressions: number;
    cpc: number;
    ctr: number;
    cvr: number;
  };
  campaignOverview?: {
    averageDuration: number;
    averageSpend: number;
  };
  campaignTemplateUpdatedAt?: Date;
  campaignTemplateEditableFields?: Array<string>;
  campaignTemplateAssignedDte?: string;
  campaignTemplateOptOutInd?: boolean;
  campaignTemplateSharedGroupIds?: Array<number>;
  campaignTemplateFilteredLocationIds?: Array<number>;
  campaignTemplateScheduleNeeded?: boolean | AdsetScheduleNeededType;
  campaignTemplateScheduleTimezone?: ScheduleTimezoneType;
  campaignTemplateScheduleList?: Array<AdsetScheduleTimesType>;
  campaignTemplateCustomListId?: string;
  campaignTemplateCustomListUploadFile?: string;
  campaignTemplateCustomListProcessingStatus?: CustomListStatus;
}

export class ITemplatesList {
  templates: Array<CampaignTemplate>;
  count: number;
}

export class IBasicCampaignTemplate {
  campaignTemplateId: string;
  campaignTemplateNme: string;
  campaignChannelNme: string;
  startDte: Date;
  endDte: Date;
  actualLocationCount: number;
  sharedLocationCount: number;
  rating: number;
  rateCount: number;
  run: number;
  updateDte: Date;
  campaignTemplateNewCampaignInd: boolean;
  active: boolean;
}

export interface ICampaignTemplateInfo {
  campaignTemplateId: string;
  campaignTemplateNme: string;
  campaignChannelId: string;
  campaignChannelNme: string;
  campaignTemplateRunTims: number;
  campaignTemplateInProgressQty: number;
}

export interface CampaignTemplateInput {
  campaignTemplateNme?: string;
  campaignTemplateSharedLocationIds?: Array<number | string>;
  campaignTemplateSharedGroupIds?: Array<number>;
  campaignTemplateFilteredLocationIds?: Array<number>;
  campaignTemplateMinAge?: number;
  campaignTemplateMaxAge?: number;
  campaignTemplateRecommendedDuration?: number;
  campaignTemplateAudienceGender?: string;
  campaignTemplateAudienceSuggestedSpend?: number;
  campaignTemplateAvailableStartDte?: Date | string;
  campaignTemplateAvailableEndDte?: Date | string;
  campaignTemplateImageUrls?: string[];
  campaignTemplateAdDsc?: string;
  campaignTemplateHeadlines?: string[];
  campaignTemplateNewsFeedlinkDscs?: string[];
  campaignTemplateActiveInd?: boolean;
  campaignTemplateDefaultWebsiteUrl?: string;
  campaignTemplateOptimizationGoal?: string;
  campaignTemplateObjectives?: string[];
  campaignTemplateGoal?: string;
  campaignTemplateCampaignCreator?: string;
  campaignTemplateWebsiteUrls?: Array<{ url: string; locationId: string }>;
  campaignTemplateEditableFields?: Array<string>;
  organizationId?: string;
  campaignTemplateNewCampaignInd?: boolean;
  channel?: IChannelData;
  campaignChannelId?: string;
  campaignChannelNme?: string;
  campaignPotentialReach?: number;
  campaignCustomEventType?: string;
  campaignPixelId?: string;
  campaignTemplateOptOutInd?: boolean;
  campaignTemplateSpecialAdCategory?: string;
  campaignTemplateScheduleNeeded?: boolean | AdsetScheduleNeededType;
  campaignTemplateScheduleTimezone?: ScheduleTimezoneType;
  campaignTemplateScheduleList?: Array<AdsetScheduleTimesType>;
  campaignTemplateRadius?: number;
  campaignTemplateCustomListId?: string;
  campaignTemplateCustomListUploadFile?: string;
}

export interface IFormGroupCampaignTemplate {
  campaignTemplateNme?: string;
  campaignTemplateSharedLocationIds?: Array<string>;
  campaignTemplateMinAge?: number;
  campaignTemplateMaxAge?: number;
  campaignTemplateRecommendedDuration?: number;
  campaignTemplateAudienceGender?: string;
  campaignTemplateAudienceSuggestedSpend?: number;
  campaignTemplateAvailableStartDte?: Date;
  campaignTemplateAvailableEndDte?: Date;
  campaignTemplateImageUrls?: string[];
  campaignTemplateVideoUrls?: string[];
  campaignTemplateAdDsc?: string;
  campaignTemplateHeadlines?: string[];
  campaignTemplateNewsFeedlinkDscs?: string[];
  campaignTemplateActiveInd?: boolean;
  campaignTemplateDefaultWebsiteUrl?: string;
  campaignTemplateOptimizationGoal?: string;
  campaignTemplateObjectives?: string[];
  campaignTemplateGoal?: string;
  campaignTemplateCampaignCreator?: string;
  campaignTemplateWebsiteUrls?: Array<{ url: string; locationId: string }>;
  campaignTemplateEditableFields?: Array<string>;
  organizationId?: string;
  campaignTemplateNewCampaignInd?: boolean;
  channel?: IChannelData;
  campaignChannelId?: string;
  campaignChannelNme?: string;
}

export interface ICanvaConfig {
  isDisplay?: boolean;
  buttonType?: string;
  isCreate?: boolean;
  isCarousel?: boolean;
  savedDesigns?: DropdownOption[];
  inputDesignId?: string;
  width?: number;
  height?: number;
}

export enum ScheduleFormatType {
  facebook = 'facebook',
  google = 'google',
}

export enum ScheduleTimezoneType {
  ADVERTISER = 'ADVERTISER',
  USER = 'USER',
  NONE = '',
}

export interface AdsetScheduleTimesType {
  startHour?: number;
  startMinute?: number;
  endHour?: number;
  endMinute?: number;
  dayOfWeek?: number;
  days?: Array<number>;
}

export enum AdsetScheduleNeededType {
  NEED = 'NEED',
  NOT_NEED = 'NOT_NEED',
  NONE = '',
}

export enum SpecialAdCategoryType {
  HOUSING = 'HOUSING',
  Employment = 'Employment',
  CREDIT = 'CREDIT',
  NONE = 'NONE',
}

export interface Campaign {
  campaignId: string;
  locationId?: string;
  location?: ILocation;
  organizationId?: string | number;
  campaignTemplate?: CampaignTemplate;
  campaignTemplateId?: string | number;
  campaignType: CampaignType;
  campaignChannelId: string;
  channel: {
    facebook?: FacebookChannel;
    google?: GoogleChannel;
    instagram?: InstagramChannel;
  };
  campaignChannelNme: ChannelName;
  campaignAudienceGender?: string;
  campaignMinAge?: number;
  campaignMaxAge?: number;
  campaignDurationStartDte?: string;
  campaignDurationEndDte?: string;
  campaignBudget?: number;
  campaignCreator: string;
  campaignMetrics?: ICampaignMetrics;
  campaignImageUrls?: Array<string>;
  campaignNme: string;
  campaignAdDsc?: string;
  campaignHeadlines?: Array<string>;
  campaignNewsFeedlinkDscs?: Array<string>;
  campaignGoal?: string;
  campaignObjectives: Array<string>;
  campaignOptimizationGoal?: string;
  campaignWebsiteUrls?: Array<{ url: string; locationId: string }>;
  campaignRanByZowi?: boolean;
  campaignChannelStatus: CampaignStatusType;
  campaignCustomEventType?: string;
  campaignPublishStatus?: string;
  campaignPaused?: boolean;
  campaignArchiveDate?: string;
  campaignDefaultWebsiteUrl?: string;
  campaignRadius?: number;
  campaignPixelId?: string;
  campaignPotentialReach?: number;
  campaignRecommendedDuration?: number;
  campaignAudienceSuggestedSpend?: number;
  campaignSpecialAdCategory?: string;
  campaignScheduleNeeded?: boolean;
  campaignScheduleTimezone?: ScheduleTimezoneType;
  campaignScheduleList?: Array<AdsetScheduleTimesType>;
}

export interface ICampaignOperation {
  campaignId: string;
  operation: CampaignOperationType;
}

export interface ICampaignUpdateInput {
  campaignId: string;
  remainingBudget: number;
  endDate: string;
  startDate?: string;
  scheduleNeeded?: boolean;
  scheduleTimeZone?: ScheduleTimezoneType;
  scheduleList?: AdsetScheduleTimesType[];
}

export interface IFranchiseeReview {
  averageRate: number;
  feedbackComment: string;
  locationName: string;
  channel?: string;
  campaignName?: string;
  templateAverageRate?: number;
  templateRateCount?: number;
}

export interface TabsFormGroup {
  publicField: {
    format: FormGroup;
    audience: FormGroup;
    tempContent: FormGroup;
    franchiseeList: FormGroup;
  };
  channelField: {
    format: {
      facebook: FormGroup;
      instagram: FormGroup;
      google: FormGroup;
    };
    audience: {
      facebook: FormGroup;
      instagram: FormGroup;
      google: FormGroup;
    };
    tempContent: {
      facebook: FormGroup;
      instagram: FormGroup;
      google: FormGroup;
    };
    franchiseeList: {
      facebook: FormGroup;
      instagram: FormGroup;
      google: FormGroup;
    };
  };
}

export interface DurationAndSpendSummary {
  weeks: number;
  days: number;
  totalSpend: number;
  singleSpend: number;
}

export interface CustomizedCampaignInput {
  strategy?: BiddingStrategyType;
  maxCPC?: number;
  startDate: string;
  endDate: string;
  isNeedSchedule: boolean;
  timezoneType: ScheduleTimezoneType;
  scheduleTimesList: AdsetScheduleTimesType[];
  budget: number;
}

export interface StartRunAdInput {
  isConvertURL: boolean;
  redirectURL: string;
  customizedInput?: CustomizedCampaignInput;
}

export enum CustomListStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  FAILED = 'FAILED',
}
