<div class="message-dialog-container">
  <div class="dialog-card-title flex-row mb-m">
    <h2 class="page-title full-width">Upload List</h2>
    <span class="close-icon" (click)="closeDialog()"></span>
  </div>

  <p class="mb-m text-m">
    Uploading a list will create an audience in the selected Ad Channel and overwrite other audience targeting
  </p>

  <input type="file" accept=".csv" class="file-input" (change)="onFileSelected($event)" #fileUpload />
  <app-ui-file-container
    *ngIf="fileName"
    [fileName]="fileName"
    (replaceClickEvent)="fileUpload.click()"
    (deleteClickEvent)="onFileDeleted()"
  ></app-ui-file-container>

  <ngx-file-drop
    *ngIf="!fileName"
    dropZoneLabel="Drop files here"
    (onFileDrop)="onFileDropped($event)"
    dropZoneClassName="file-drop-zone"
    accept=".csv"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="flex-col">
        <app-ui-button class="mb-s" [btnType]="'secondary'" (clickEvent)="openFileSelector()"
          >Upload List</app-ui-button
        >
        <p class="text-m">or drop file to upload here</p>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div *ngIf="this.channelRequirementsLink" class="flex-row flex-justify-start mt-m">
    <a [href]="this.channelRequirementsLink" target="_blank" class="mr-1 link-text text-capitalize text-m"
      >{{ this.uploadListData.channelName }} Requirements</a
    >
    <app-ui-icon [icon]="'Vector_Stroke'" [size]="0.8"></app-ui-icon>
  </div>

  <p class="mb-m text-m">
    If the uploaded file does not match the above requirements, the upload may fail and result in an audience of 0.
    <br />
    Processing a custom list can take up to 24 hours.
  </p>

  <div class="message-dialog-button-group">
    <app-ui-button class="message-dialog-button" btnType="secondary" (clickEvent)="closeDialog()">Cancel</app-ui-button>
    <app-ui-button class="message-dialog-button" (clickEvent)="submitDialog()" [disabled]="!file">Submit</app-ui-button>
  </div>
</div>
