import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ui-file-container',
  templateUrl: './ui-file-container.component.html',
  styleUrls: ['./ui-file-container.component.scss'],
})
export class UiFileContainerComponent {
  @Input()
  fileName: string;
  @Input()
  formGroup: FormGroup;
  @Input()
  controlName: string;

  @Output()
  replaceClickEvent: EventEmitter<Event> = new EventEmitter();
  @Output()
  deleteClickEvent: EventEmitter<Event> = new EventEmitter();

  constructor() {}

  get formControl(): AbstractControl {
    const formGroup = this.formGroup;
    const controlName = this.controlName;
    if (!!formGroup && !!controlName) {
      return formGroup.get(controlName);
    }
    return null;
  }

  get isFileInvalid() {
    const formControl = this.formControl;
    return (
      formControl &&
      formControl.invalid &&
      (formControl.dirty || formControl.touched) &&
      this.formControl.hasError('invalidFile')
    );
  }

  onReplace(): void {
    this.replaceClickEvent.emit();
  }

  onDelete(): void {
    this.deleteClickEvent.emit();
  }
}
