import { CampaignTemplate } from './campaign.interface';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ViewType } from './user.interface';
import { PoliciesEnum } from './policy.interface';
import { Params } from '@angular/router';
import { Assignment } from '@constants';
import { CustomListData } from './custom-list.interface';

export interface NavigationMenu {
  navId: string;
  policyIds?: PoliciesEnum[];
  navName: string;
  navPath: string;
  navType: string;
  navLabel?: string;
  excludeViewTypes?: ViewType[];
  isActive: boolean;
  isEnable?: boolean;
  queryParams?: Params;
  newApp?: boolean;
  assignment?: Assignment;
}

export interface IAdContent {
  adId: string;
  templateId: string;
  adName: string;
  adObjective: string;
  adGoals: string;
  adChannel: string;
  adDsc: string;
  adResult: {};
  adRate: number;
  adRateCount: number;
  adImgURL: string;
  adAssignedDate: string;
  adNew: boolean;
  location?: string;
}

export interface ICharactersLimit {
  adText?: number;
  headline?: number;
  linkDesc?: number;
  adDesc?: number;
}

export interface DictionaryInterface {
  googleChannelId?: string;
  googleChannelName?: string;
  googleAdIdList?: string;
  googleKeywords?: string;
  googleNegKeywords?: string;
  googleNegKeywordsType?: string;
  googleAdDesc?: string;
  googleAdGrpId?: string;
  googleAdGrpName?: string;
  googleAdPathA?: string;
  googleAdPathB?: string;
  googleAdHeadline1?: string;
  googleAdHeadline2?: string;
  googleAdFinalUrl?: string;
  googleAdBiddingStrategy?: string;
  googleAdDailyBudget?: string;
  googleAdServing?: string;
  googleAdMaxCPC: number;
  channelName?: string;
  campaignChannelId?: string;

  campaignStart?: string;
  campaignEnd?: string;

  adSetName?: string;
  adSetId?: string;
  detailedTargeting?: string;
  mediaType?: string;
  socialMedia?: string;
  adText?: string;
  cta?: string;
  displayLink?: string;
  websiteUrl?: string;
  isFullScreen?: string;
  traffic?: string;

  minAge?: string;
  maxAge?: string;
  startDate?: string;
  endDate?: string;
  imgUrls?: string;
  imgHeadlines?: string;
  imgLinkDescs?: string;
  objectives?: string;
  websiteUrls?: string;
  gender?: string;
  adDesc?: string;
  goal?: string;
  optimizationGoal?: string;
  defaultWebsiteUrl?: string;
  createDate?: string;
  updateDate?: string;
  isNew?: string;
  suggestedSpend?: string;
  recommendedDuration?: string;

  templateCreator?: string;
  templateSharedLocationIdList?: string;
  templateId?: string;
  templateName?: string;
  templateIsActive?: string;
  templateTakeRate?: string;
  templateRunTimes?: string;
  templateRateFeedback?: string;
  feedbackAvgRate?: string;
  feedbackRateQty?: string;
  templateInProgressQty?: string;
  templateEditableFieldList?: string;

  campaignCreator?: string;
  campaignType?: string;
  campaignId?: string;
  campaignName?: string;
  campaignBudget?: string;
  campaignTemplate?: string;
  campaignTemplateId?: string;

  locationId?: string;
  locationName?: string;
  locCity?: string;
  locState?: string;
  locRadius?: string;
  locZips?: string;
  locPhone?: string;
  locSelfState?: string;
  locSelfZip?: string;
  locAddr?: string;
  locAdditionalAddr?: string;
  locWebUrl?: string;
  locContactName?: string;
  locContactMail?: string;

  orgId?: string;
  orgParentId?: string;
  orgParent?: string;
  orgChildren?: string;
  orgName?: string;
  orgType?: string;
  orgContactName?: string;
  orgContactEmail?: string;
  orgState?: string;
  orgAddr?: string;

  // [key: string]: string;
}

export interface IDuration {
  startDate: Date;
  endDate: Date;
}

export interface IPageInfo {
  templates: Array<CampaignTemplate>;
  count: number;
}

export interface IPaginationInfo {
  pageNum?: number;
  pageSize?: number;
  totalCount?: number;
  pageCount?: number;
}

export interface RadioButtonOption {
  value: string | boolean;
  text: string;
  icon?: string;
  fillIcon?: string;
}

export enum IconType {
  Fill = 'fill',
  Outline = 'outline',
  Logo = 'logo',
}

/* used for MessagePromptDialogComponent */
export enum MessagePromptDialogActions {
  // click on the close icon
  CloseIcon = 'closeIcon',
  // click on the button if isRouterBtn is true
  Router = 'router',
  // click on the button if isLinkBtn is false and isRouterBtn is false
  Button = 'button',
  // click on the button if isLinkBtn is true
  Link = 'link',
  // click on the detailed content in major section if isRouter is true
  SubRouter = 'subRouter',
}

export interface DetailedContentInterface {
  content: string;
  isNormal?: boolean;
  isLink?: boolean;
  isRouter?: boolean;
  href?: string;
  routerString?: string | string[];
}

export interface MessagePromptDialogDataInterface {
  content: DetailedContentInterface[][];
  withClose?: boolean;
  payloadInClose?: any;
  withIcon?: boolean;
  isRouterBtn?: boolean;
  router?: string[];
  isLinkBtn?: boolean;
  link?: string;
  btnString?: string;
  isSucceed?: boolean;
  isEmotion?: boolean;
}

export declare type CheckFn<CT, T> = (compared: CT, arrayItem: T) => boolean;

export interface IChipObject {
  chipName: string;
  addToText: boolean;
  charDropCount: number;
  index?: number;
  placeholderString: string;
  startIndex?: number;
  needDelete: boolean;
}

export interface ICaretInfo {
  selectionStart: number;
  selectionEnd: number;
}

export interface IScrollInfo {
  oldScrollLeft?: number;
  oldScrollTop?: number;
  newScrollLeft?: number;
  newScrollTop?: number;
}

export interface ITextareaStyle {
  fontSize?: string;
  fontFamily?: string;
  lineHeight?: string;
  // belows will use for calculate, use int
  totalWidth?: number;
  totalHeight?: number;
  borderRightWidth?: number;
  borderLeftWidth?: number;
  borderTopWidth?: number;
  borderBottomWidth?: number;
  paddingRightWidth?: number;
  paddingLeftWidth?: number;
  paddingTopWidth?: number;
  paddingBottomWidth?: number;
  availableWidth?: number;
  availableHeight?: number;
}

export interface DropdownOption {
  text: string;
  value: string | number;
}

export interface ICheckboxOption {
  text: string;
  value: string | number;
  checked: boolean;
  active?: boolean;
}

export interface IAdMetricTypes {
  text: string;
  value: string;
  field?: string;
  type?: string;
  icon?: string;
  color?: string;
  recommendedLabel?: string;
}

// datatable
export interface ITableColumn {
  prop: string;
  name: string;
  dir?: string;
  isHover?: boolean;
  icon?: string;
  isLeft?: boolean;
  grow?: number;
  width?: number;
  isCheckbox?: boolean;
  sortable?: boolean;
  withTabIndex?: boolean;
  type?: string;
  disabled?: boolean;
}

export interface ITableSort {
  prop: string;
  dir: string;
}

export interface ITableHeaderEvent {
  column: TableColumn;
  newValue?: string;
  prevValue?: string;
  sorts?: Array<{ dir: string; prop: string }>;
}

export interface IFilterValue {
  value: string | { text: string; value: string } | { fromDate: Date; toDate: Date };
}

export interface Tab {
  text: string;
  tabName: string;
  enable?: boolean;
  valid?: boolean;
}

export interface ITableListHeader {
  label: string;
  isCenter: boolean;
  prop?: string;
  order?: string;
  isSortable?: boolean;
  id?: string;
}

export interface IKPMItem {
  label: string;
  key: string;
  abbr: string;
  name: string;
  defaultValue: number | string;
  type: string;
  tooltip: string;
  src?: string;
  value?: number | string;
}

export interface APIVersion {
  version: string;
  branch: string;
  commit: string;
}

export interface ValidationResult {
  [key: string]: boolean;
}

export interface IGSheetsSync {
  success: boolean;
  message: Array<string>;
}

export enum DatePickerType {
  DateSimple = 'DateSimple',
  DateRangeStart = 'DateRangeStart',
  DateRangeEnd = 'DateRangeEnd',
}

export interface FieldType {
  title?: string;
  type: string;
  fieldType?: string /*'public' or 'channel'*/;
  controlName?: string;
  disableSameDay?: boolean /* use for datepicker */;
  startControlName?: string /* use for datepicker */;
  endControlName?: string /* use for datepicker */;
  limitDate?: { fromDate?: Date; toDate?: Date } /* use for datepicker */;
  inputLimit?: number;
  mediaAccept?: string;
  options?: Array<Option>;
  text?: string;
  subText?: string;
  preText?: string;
  endText?: string;
  status?: string /* used for custom list */;
  customListData?: CustomListData /* used for custom list */;
  isReadOnly?: boolean /* used for custom list */;
  width?: string;
  inputMaxWidth?: string;
  editableName?: string;
  placeholder?: string;
  inputDescription?: string;
  tip?: string;
  indexInArray?: number /* use for headlines and newDecs */;
  isArrayValue?: boolean /* use for headlines and newDecs */;
  noTipIcon?: boolean;
  isOptional?: boolean;
  isSubline?: boolean;
  isError?: boolean /* use for upload */;
  isThumbnail?: boolean /* use for upload */;
  isSeparate?: boolean;
  decimalPoint?: boolean;
  halfLine?: boolean;
  chipLengthLimit?: number;
  additionalInfos?: Array<string>;
  iconSize?: number /* user for radio button group*/;
  controlNames?: Array<string> /*use for group ship text*/;
  inputPrefixes?: Array<any> /*use for group ship text*/;
  name?: string;
  class?: string;
  disabled?: boolean;
  label?: string;
  value?: any;
  subType?: string;
  timezoneControlName?: string /*use for time schedule component*/;
  schedulesControlName?: string /*use for time schedule component*/;
  preconditionFieldType?: string /*use for time schedule component*/;
  preconditionControlName?: string /*use for time schedule component*/;
  showSelected?: boolean /*used for checkbox dropdown */;
}

export interface Option {
  value: string | number;
  text?: string;
  tip?: string;
  status?: boolean;
  icon?: string;
  fillIcon?: string;
  logo?: string;
  disabled?: boolean;
}

export interface CommonResponse {
  success: boolean;
  message: string;
}
