import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
  DurationAndSpendSummary,
  GenderType,
  Targeting,
  SpecialAdCategoryType,
  TargetingInput,
  DetailedTargetingInput,
  TargetingEstimate,
  ILocation,
  Campaign,
  CampaignTemplate,
  ChannelName,
  CustomizedCampaignInput,
  StartRunAdInput,
  GoogleChannel,
} from '@interfaces';
import { TargetingService } from '../targeting/targeting.service';
import { AdTextChipParseService } from '../ad-text-parse/ad-text-chip-parse.service';
import { CampaignService } from '../campaign/campaign.service';
import { ManageUserSocialAccountsService } from '../manage-user-social-accounts/manage-user-social-accounts.service';
import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { DatepickerService } from '../datepicker/datepicker.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, skipWhile, take, toArray } from 'rxjs/operators';
import { cloneDeep, times, find, capitalize, omit } from 'lodash';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublishAdDialogComponent } from '@dialogs';
import { CommonDialogService } from '../common-dialog.service';
import { Router } from '@angular/router';

interface PotentialReachRequest {
  getLocationPotentialReach: Observable<TargetingEstimate>;
  locationId: string;
}

interface LocationPotentialReach {
  locationId: string;
  potentialReach: number;
}

@Injectable({
  providedIn: 'root',
})
export class RunAdService {
  pixelId: string;
  conversionEvent: string;

  private potentialReachFinished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private startRunAd$: BehaviorSubject<StartRunAdInput> = new BehaviorSubject<StartRunAdInput>(null);
  private runAdFinished$: BehaviorSubject<{ isSucceed: boolean }> = new BehaviorSubject<{ isSucceed: boolean }>(null);
  private locationsPotentialReach: LocationPotentialReach[] = [];
  private currentTemplate: CampaignTemplate;
  private runAdLocations: ILocation[] = [];
  private currentChannel: string;
  private allowCreateCampaign: boolean;
  private publishDialogRef: MatDialogRef<PublishAdDialogComponent>;
  private customizedCampaignInput: CustomizedCampaignInput;
  private isConvertURL: boolean;
  private redirectURL: string;

  constructor(
    private targetingService: TargetingService,
    private adChipParseService: AdTextChipParseService,
    private campaignService: CampaignService,
    private socialAccountService: ManageUserSocialAccountsService,
    private googleAnalytics: GoogleAnalyticsService,
    private datepickerService: DatepickerService,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogService: CommonDialogService,
    private router: Router,
  ) {}

  calculateDurationAndSpend(
    startDate: string,
    endDate: string,
    budget: number,
    locationsLength: number,
  ): DurationAndSpendSummary {
    const totalDays = moment(new Date(endDate)).diff(moment(new Date(startDate)), 'days') + 1;
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;
    const singleSpend = Number(((budget * totalDays) / 7).toFixed(2));
    const totalSpend = Number((singleSpend * locationsLength).toFixed(2));
    return {
      days,
      weeks,
      totalSpend,
      singleSpend,
    };
  }

  setPixelId(pixelId: string): void {
    this.pixelId = pixelId;
  }

  setConversionEvent(conversionEvent: string): void {
    this.conversionEvent = conversionEvent;
  }

  resetRunAdStatus(): void {
    this.potentialReachFinished$.next(false);
    this.startRunAd$.next(null);
    this.runAdFinished$.next(null);
    this.pixelId = null;
    this.conversionEvent = null;
    this.currentTemplate = null;
    this.currentChannel = null;
    this.allowCreateCampaign = false;
    this.publishDialogRef = null;
    this.customizedCampaignInput = null;
    this.runAdLocations = [];
    this.locationsPotentialReach = [];
  }

  trackEvent(type: 'run' | 'scheduled', action: string, label: string): void {
    const category = type === 'run' ? 'Run a New Ad' : 'Scheduled Campaign';
    this.googleAnalytics.eventTracking(category, action, label);
  }

  runAdFinished(runAdInput: StartRunAdInput): Observable<{ isSucceed: boolean }> {
    this.startRunAd();
    return this.potentialReachFinished$.pipe(
      skipWhile((isFinished) => !isFinished),
      mergeMap(() => {
        this.startRunAd$.next(runAdInput);
        return this.runAdFinished$.pipe(skipWhile((status) => !status));
      }),
    );
  }

  getPotentialReach(template: CampaignTemplate, runAdLocations: ILocation[]): void {
    this.currentTemplate = { ...template };
    this.currentChannel = template.campaignChannelNme;
    this.runAdLocations = [...runAdLocations];
    this.openPublishDialog();
    if (this.currentChannel === ChannelName.GOOGLE) {
      this.potentialReachFinished$.next(true);
      return;
    }
    const targeting = this.getTargeting(template, [...runAdLocations]);
    const potentialReachRequestList: PotentialReachRequest[] = [];
    const getLocationsPotentialReach = () => {
      runAdLocations.forEach((location) => {
        const targetingData = this.getTargeting(template, [location]);
        const geoLocations = this.targetingService.getGeoLocations({
          locations: targetingData.locations,
          specialAdCategory: targetingData.specialAdCategory,
          templateRadius: targetingData.templateRadius,
        });
        potentialReachRequestList.push({
          getLocationPotentialReach: this.targetingService.getPotentialReachByLocation(targetingData, geoLocations),
          locationId: location.locationId,
        });
      });
      combineLatest(potentialReachRequestList.map((item) => item.getLocationPotentialReach)).subscribe(
        (potentialReachResults) => {
          if (potentialReachResults.length > 0) {
            potentialReachResults.forEach((potentialReachEstimation: TargetingEstimate, index: number) => {
              if (!!potentialReachEstimation && potentialReachEstimation.estimateReady) {
                this.locationsPotentialReach.push({
                  locationId: potentialReachRequestList[index].locationId,
                  potentialReach: potentialReachEstimation.estimateMauUpperBound,
                });
              }
            });
            this.potentialReachFinished$.next(true);
          }
        },
      );
    };
    this.targetingService.getLocationState(targeting, getLocationsPotentialReach);
  }

  getTargeting = (template: CampaignTemplate, selectedLocations: ILocation[]): Targeting => {
    let genders = [];
    let detailedTargeting = [];
    const channelName = template.campaignChannelNme;
    const campaignTemplateAudienceGender = template.campaignTemplateAudienceGender;
    const channelDetailTargeting = template.channel[channelName][`${channelName}ChannelDetailTargeting`];
    const campaignTemplateMinAge = template.campaignTemplateMinAge;
    const campaignTemplateMaxAge = template.campaignTemplateMaxAge;
    const campaignTemplateOptimizationGoal = template.campaignTemplateOptimizationGoal;
    const campaignTemplateSpecialAdCategory = template.campaignTemplateSpecialAdCategory;
    const campaignTemplateRadius = template.campaignTemplateRadius;
    switch (campaignTemplateAudienceGender) {
      case GenderType.BOTH: {
        genders = [1, 2];
        break;
      }
      case GenderType.MALE: {
        genders = [1];
        break;
      }
      case GenderType.FEMALE: {
        genders = [2];
        break;
      }
      default: {
        break;
      }
    }
    try {
      detailedTargeting = JSON.parse(channelDetailTargeting);
    } catch (error) {
      detailedTargeting = [];
    }
    return {
      age_min: campaignTemplateMinAge,
      age_max: campaignTemplateMaxAge,
      channelName,
      optimizationGoal: campaignTemplateOptimizationGoal,
      detailedTargeting,
      genders,
      locations: selectedLocations,
      specialAdCategory: campaignTemplateSpecialAdCategory as SpecialAdCategoryType,
      templateRadius: campaignTemplateRadius ? `${campaignTemplateRadius} mi` : null,
    } as Targeting;
    // tslint:disable-next-line:semicolon
  };

  private getLocationPotentialReach(locationId: string): number {
    const locationWithPotentialReach = this.locationsPotentialReach.find((item) => item.locationId === locationId);
    return !!locationWithPotentialReach ? locationWithPotentialReach.potentialReach : 0;
  }

  private startRunAd(): void {
    this.startRunAd$
      .pipe(
        filter((runAdInput) => !!runAdInput),
        take(1),
      )
      .subscribe((runAdInput: StartRunAdInput) => {
        const { isConvertURL, customizedInput, redirectURL } = runAdInput;
        this.isConvertURL = isConvertURL;
        this.customizedCampaignInput = customizedInput;
        this.redirectURL = redirectURL;
        this.allowCreateCampaign = true;
        this.createCampaigns();
      });
  }

  private createCampaigns(): void {
    merge(...this.createCampaignQueue())
      .pipe(toArray())
      .subscribe(
        (ids) => {
          this.trackCreateStatus(ids);
        },
        () => {
          this.createCampaignFailed();
        },
      );
  }

  private openPublishDialog(): void {
    this.publishDialogRef = this.dialog.open(PublishAdDialogComponent, {
      width: '90%',
      maxWidth: '30em',
      disableClose: true,
      data: {
        channel: this.currentChannel,
      },
    });
  }

  private closePublishDialog(): void {
    if (this.publishDialogRef) {
      this.publishDialogRef.close();
      this.resetRunAdStatus();
    }
  }

  private createCampaignQueue(size = 5): Observable<number>[] {
    const campaignList: Campaign[] = this.buildCampaignData();
    if (campaignList.length < size) {
      size = campaignList.length;
    }
    let index = 0;
    const createSingleCampaignQueue = () => {
      return new Observable<number>((subscriber) => {
        const createCampaignTask = () => {
          if (index < campaignList.length && this.allowCreateCampaign) {
            const currentIndex = index++;
            const pendingCampaign = campaignList[currentIndex];

            this.createCampaignRequest(pendingCampaign, currentIndex)
              .pipe(take(1))
              .subscribe((campaign) => {
                if (campaign) {
                  subscriber.next(Number(campaign.campaignId));
                  createCampaignTask();
                } else {
                  this.createCampaignFailed();
                }
              });
          } else {
            subscriber.complete();
          }
        };
        createCampaignTask();
      });
    };
    return times(size, createSingleCampaignQueue);
  }

  private buildCampaignData(): Campaign[] {
    const campaignList = [];
    this.runAdLocations.forEach((location) => {
      campaignList.push(this.buildCampaignInputData(location));
    });
    return campaignList;
  }

  private buildCampaignInputData(location: ILocation): Campaign {
    const template = this.currentTemplate;
    const campaign = {} as Campaign;
    campaign.locationId = location.locationId;
    campaign.campaignTemplateId = template.campaignTemplateId;
    const fixedFields = [
      'Nme',
      'SpecialAdCategory',
      'AudienceGender',
      'RecommendedDuration',
      'AudienceSuggestedSpend',
      'Goal',
      'OptimizationGoal',
      'AdDsc',
      'DefaultWebsiteUrl',
      'CustomListId',
    ];
    const numericFields = ['MinAge', 'MaxAge', 'Radius'];
    const deepCloneFields = ['Channel', 'WebsiteUrls', 'Objectives', 'Headlines', 'NewsFeedlinkDscs'];
    fixedFields.forEach((fixedField) => {
      campaign[`campaign${fixedField}`] = template[`campaignTemplate${fixedField}`];
    });
    numericFields.forEach((numericField) => {
      campaign[`campaign${numericField}`] = Number(template[`campaignTemplate${numericField}`]);
    });
    deepCloneFields.forEach((deepCloneField) => {
      if (deepCloneField === 'Channel') {
        campaign.channel = cloneDeep(template.channel);
      } else {
        campaign[`campaign${deepCloneField}`] = cloneDeep(template[`campaignTemplate${deepCloneField}`]);
      }
    });

    // customized fields
    if (template.campaignTemplateGoal === 'conversion') {
      campaign.campaignCustomEventType = this.conversionEvent;
      campaign.campaignPixelId = this.pixelId;
    }
    if (template.campaignChannelNme === ChannelName.GOOGLE) {
      const google = campaign.channel.google;
      google.googleChannelDsc = this.modifyAdTextByLocation(location, google.googleChannelDsc);
      google.googleChannelDsc2 = this.modifyAdTextByLocation(location, google.googleChannelDsc2);
    } else if (template.campaignChannelNme === ChannelName.FACEBOOK) {
      const facebook = campaign.channel.facebook;
      facebook.facebookChannelAdTxt = this.modifyAdTextByLocation(location, facebook.facebookChannelAdTxt);
    } else {
      const instagram = campaign.channel.instagram;
      instagram.instagramChannelAdTxt = this.modifyAdTextByLocation(location, instagram.instagramChannelAdTxt);
    }
    campaign.campaignPotentialReach = this.getLocationPotentialReach(location.locationId);
    const { campaignFields, googleChannelFields } = this.getSpecialFieldsInput();
    if (googleChannelFields) {
      Object.assign(campaign.channel.google, googleChannelFields);
    }
    return Object.assign(campaign, campaignFields);
  }

  private modifyAdTextByLocation(location: ILocation, textInput: string): string {
    if (!textInput) {
      return textInput;
    }
    const res = this.adChipParseService.parseAdTextInZeePageWithSingleLocation(textInput, location);
    let tmpStr = '';
    for (const field of res) {
      tmpStr += field['content'].trim() + ' ';
    }
    return tmpStr.trim().replace(/\s{2,}/g, ' ');
  }

  private getSpecialFieldsInput(): { campaignFields: Partial<Campaign>; googleChannelFields: Partial<GoogleChannel> } {
    const template = this.currentTemplate;
    const isGoogle = template.campaignChannelNme === ChannelName.GOOGLE;
    let campaignFields: Partial<Campaign>;
    let googleChannelFields: Partial<GoogleChannel>;
    if (this.customizedCampaignInput) {
      const { strategy, maxCPC, startDate, endDate, isNeedSchedule, timezoneType, scheduleTimesList, budget } =
        this.customizedCampaignInput;
      campaignFields = {
        campaignBudget: budget,
        campaignDurationStartDte: startDate,
        campaignDurationEndDte: endDate,
        campaignScheduleNeeded: isNeedSchedule,
        campaignScheduleTimezone: timezoneType,
        campaignScheduleList: scheduleTimesList,
      };
      if (isGoogle) {
        googleChannelFields = {
          googleChannelBiddingStrategy: strategy,
          googleChannelAdGroupCpcBid: maxCPC,
        };
      }
    } else {
      campaignFields = {
        campaignBudget: template.campaignTemplateAudienceSuggestedSpend,
        campaignDurationStartDte: this.datepickerService.getStringFromStrOrDate(
          template.campaignTemplateAvailableStartDte,
        ),
        campaignDurationEndDte: this.datepickerService.getStringFromStrOrDate(template.campaignTemplateAvailableEndDte),
        campaignScheduleNeeded: template.campaignTemplateScheduleNeeded as boolean,
        campaignScheduleTimezone: template.campaignTemplateScheduleTimezone,
        campaignScheduleList: template.campaignTemplateScheduleList,
      };
      if (isGoogle) {
        googleChannelFields = {
          googleChannelBiddingStrategy: template.channel.google.googleChannelBiddingStrategy,
          googleChannelAdGroupCpcBid: template.channel.google.googleChannelAdGroupCpcBid,
        };
      }
    }
    if (this.isConvertURL) {
      campaignFields.campaignImageUrls = [
        ...template.campaignTemplateImageUrls.map((url) => {
          if (!!url) {
            const res = this.campaignService.transSignedUrlToDBUrl(url);
            return res || '';
          }
          return '';
        }),
      ];
    } else {
      campaignFields.campaignImageUrls = [...template.campaignTemplateImageUrls];
    }
    const scheduleList = campaignFields.campaignScheduleList || [];
    campaignFields.campaignScheduleList = scheduleList.map((campaignSchedule) => {
      return omit(campaignSchedule, '__typename');
    });
    return { campaignFields, googleChannelFields };
  }

  private createCampaignRequest(campaign, index): Observable<Campaign> {
    const { organization } = this.userService.getCachedUserProfile();
    if (this.userService.isZOWIView() && this.runAdLocations[index].organizationId !== organization.organizationId) {
      this.trackEvent('scheduled', 'Franchisor run campaign for franchisee', 'Zor4Zee campaign');
    }
    if (campaign.campaignGoal === 'conversion') {
      this.trackEvent('scheduled', 'Conversion event added', 'Conversion ad ran');
    }
    if (campaign.campaignScheduleNeeded) {
      this.trackEvent('scheduled', 'Run with Dayparting', 'Run with Dayparting');
    }
    return this.campaignService.createCampaign(campaign);
  }

  private trackCreateStatus(ids: number[]): void {
    if (this.allowCreateCampaign) {
      const queryRef = this.campaignService.getCampaignProcessingStatus(ids);
      let trackCampaignInterval = setInterval(() => {
        queryRef.refetch();
      }, 5000);

      setTimeout(() => {
        if (trackCampaignInterval) {
          clearInterval(trackCampaignInterval);
          this.createCampaignTimeout();
        }
      }, 300000);

      queryRef.valueChanges
        .pipe(
          map(({ data }) => {
            return data.trackCampaignEvents;
          }),
          catchError(() => of([])),
        )
        .subscribe((status) => {
          if (!this.allowCreateCampaign) {
            clearInterval(trackCampaignInterval);
            trackCampaignInterval = null;
            this.cancelCampaignCreation(ids);
            return;
          }

          const failedCampaign = find(status, (item) => {
            return item.status === 'failed';
          });
          if (!!failedCampaign) {
            clearInterval(trackCampaignInterval);
            trackCampaignInterval = null;
            // await this.checkConnectStatus();
            this.createCampaignFailed(`There was an error creating the ad. ${failedCampaign.message}.`);
            return;
          }

          const processingCampaign = find(status, (item) => {
            return item.status !== 'stepTwoCompleted';
          });
          if (!processingCampaign) {
            clearInterval(trackCampaignInterval);
            trackCampaignInterval = null;
            this.createCampaignSuccess();
          }
        });
    } else if (ids.length) {
      this.cancelCampaignCreation(ids);
    } else {
      this.cancelCampaignSuccess();
    }
  }

  private checkConnectStatus(): void {
    this.socialAccountService.getAccountsConnectedStatus().subscribe((connectStatus) => {
      // TODO: check if it works correctly as it currently shows failed message in any case
      if (!connectStatus[this.currentChannel]) {
        //   this.createCampaignFailed(
        //     'Your campaign has failed for some reasons. Please check the later dead letter email for details.',
        //   );
        // } else {
        this.createCampaignFailedReConnect(
          `For security purposes, ${capitalize(
            this.currentChannel,
          )} is requiring that you reauthorize this application`,
        );
      }
    });
  }

  private cancelCampaignCreation(ids: number[]): void {
    this.campaignService.cancelCampaignEvents(ids).subscribe(() => {
      const queryRef = this.campaignService.trackCampaignCancellation(ids);
      let trackCancelCampaignInterval = setInterval(() => {
        queryRef.refetch();
      }, 5000);

      setTimeout(() => {
        if (trackCancelCampaignInterval) {
          clearInterval(trackCancelCampaignInterval);
          this.cancelCampaignTimeout();
        }
      }, 180000);

      queryRef.valueChanges
        .pipe(
          map(({ data }) => data.trackCampaignCancellation),
          catchError(() => of([])),
        )
        .subscribe((status) => {
          const failedCampaign = find(status, (item) => {
            return item.status === 'failed';
          });
          if (failedCampaign) {
            clearInterval(trackCancelCampaignInterval);
            trackCancelCampaignInterval = null;
            this.createCampaignFailed(
              'There was an error cancelling the ad. System will try again. Please come back and check later.',
            );
            return;
          }

          const processCancelCampaign = find(status, (item) => {
            return item.status !== 'completed';
          });
          if (!processCancelCampaign) {
            clearInterval(trackCancelCampaignInterval);
            trackCancelCampaignInterval = null;
            this.cancelCampaignSuccess();
          }
        });
    });
  }

  private createCampaignTimeout(): void {
    this.closePublishDialog();
    const message =
      'You can navigate to other pages now while the creation is still undergoing. You can check the result on Ad Result page later.';
    this.openDialog(message);
  }

  private cancelCampaignTimeout(): void {
    this.closePublishDialog();
    this.openDialog('Cancel request is still running at backend. You can navigate to other pages now.');
  }

  private createCampaignFailed(errorMsg = 'There was an error submitting new ad. Please try again later.'): void {
    this.closePublishDialog();
    this.openDialog(errorMsg, 'warning');
  }

  private cancelCampaignSuccess(): void {
    this.closePublishDialog();
    this.openDialog('You have successfully canceled the ad.');
  }

  private createCampaignFailedReConnect(errorMsg: string): void {
    this.closePublishDialog();
    this.openDialog(errorMsg, 'warning', 'app/account');
  }

  private createCampaignSuccess(): void {
    this.closePublishDialog();
    this.openDialog('You have successfully submitted your ad. It will be active shortly.');
  }

  private openDialog(message: string, type: string = 'success', redirectURL?: string): void {
    this.runAdFinished$.next({ isSucceed: type === 'success' });
    this.dialogService
      .openDialog('message', {
        data: {
          dialogText: message,
          dialogType: type,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl(`/${redirectURL || this.redirectURL}`);
      });
  }
}
