import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
declare const process: any;

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  readonly apiServerURL: string;
  public graphqlApiURL: string;
  public googleUAId: string;
  public uploadFilesURL: string;
  public downloadFileURL: string;
  public customListFacebookURL: string;
  public versionURL: string;
  public pendoAPIKey: string;
  public canvaAPIKey: string;
  public userApiUrl: string;
  public canvaAPI: Promise<any>;
  public logoutUrl: string;
  public billingApiUrl: string;
  public organizationApiUrl: string;
  public reportingApiUrl: string;

  constructor() {
    this.apiServerURL = 'http://localhost:8080';
    this.apiServerURL = this._readString('API_SERVER_URL', this.apiServerURL);
    this.graphqlApiURL = this._readString('GRAPHQL_API_URL', this.apiServerURL + '/graphql');
    this.uploadFilesURL = this._readString('UPLOAD_FILES_URL', this.apiServerURL + '/api/v1/file');
    this.downloadFileURL = this._readString('DOWNLOAD_FILE_URL', this.apiServerURL + '/api/v1/file');
    this.customListFacebookURL = this._readString(
      'CUSTOM_LIST_FACEBOOK_URL',
      this.apiServerURL + '/api/v1/custom-list/facebook',
    );
    this.googleUAId = this._readString('GOOGLE_UA_ID', '');
    this.pendoAPIKey = this._readString('PENDO_API_KEY', '');
    this.canvaAPIKey = this._readString('CANVA_API_KEY', '');
    this.versionURL = this.apiServerURL + '/api/v1/version.json';
    this.userApiUrl = this._readString('USER_API_URL', '');
    this.logoutUrl = this._readString('LOGOUT_URL', `${window.location.origin}/ui/logout`);
    this.billingApiUrl = this._readString('BILLING_API_URL', '');
    this.organizationApiUrl = this._readString('ORGANIZATION_API_URL', '');
    this.reportingApiUrl = this._readString('REPORTING_API_URL', '');
  }

  private _readString(key: string, defaultValue?: string): string {
    const v = environment[key];
    return v ? String(v) : defaultValue;
  }
}
